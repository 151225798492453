.container {
  text-align: center;
  
  background-color: #BDD4BA;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.responsive {
  width: 100%;
  height: auto;
}

.socials{
  display: flex;
  flex-direction: row;
  gap: 10px;

  svg:hover{
    cursor: pointer;
  }
}